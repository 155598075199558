* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  vertical-align: top;
  outline: 0;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

body {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: #000;
  background: #fff;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  margin: 0;
  padding: 0;
}

.popular-slider {
  position: relative;

  .image-container {
    max-height: 421px;
    max-width: 100%;
    overflow: hidden;
    position: relative;
  }

  .box-background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 250px 20px 30px;
    background: linear-gradient(90deg, #000, transparent);
    background: -webkit-linear-gradient(90deg, #000, transparent);
  }

  .box-title {
    position: absolute;
    bottom: 20px;
    left: 0;
    padding: 0 20px;

    a {
      color: #fff;
      text-decoration: none;
      vertical-align: top;
      outline: none;
      transition: all 0.2s ease;

      &:hover {
        color: #fff;
      }

      h1 {
        font-weight: 700;
      }
    }
  }

  .box-label {
    position: absolute;
    top: 10px;
    left: 0;
    display: inline-block;
    background: #000;
    color: #fff;
    padding: 10px 20px;
    font-weight: bold;
    letter-spacing: 3px;
    font-style: italic;
  }
}

.popular-mini {
  img {
    width: 100%;
  }
}

.heading-title {
  position: relative;
  padding: 20px 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-style: italic;
  font-size: 21px;
  font-weight: 700;
  color: #0bb1ef;

  &::after {
    content: "";
    border-top: 1px solid #0bb1ef;
    position: absolute;
    z-index: -1;
    top: 32px;
    left: 0;
    width: 100%;
  }

  span {
    background: #fff;
    padding-right: 20px;
  }
}

.list-post {
  padding: 0;

  li {
    list-style: none;
    margin-bottom: 20px;

    .latest-category {
      display: inline-block;
      padding: 0 0 3px;
      text-transform: uppercase;
      border-bottom: 1px solid #0bb1ef;
      letter-spacing: 2.4px;
      font-size: 12px;
      color: #0bb1ef;
      transition: all 0.2s ease;
    }

    .latest-title {
      margin: 6px 0;
      font-size: 20px;
      font-weight: 700;
      height: auto;
      // max-height: 81px;
      overflow: hidden;
      display: block;
    }
  }
}

.subheading-title {
  position: relative;
  padding: 0 0 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-style: italic;
  text-align: left;
  font-size: 21px;
  font-weight: 700;
  color: #0bb1ef;
  border-bottom: 1px solid #0bb1ef !important;
}

.subheading-title2 {
  position: relative;
  padding: 20px 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-style: italic;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  border-bottom: 1px solid #fff;
}

.popular-post-list {
  li {
    background: transparent;
  }

  .rank-post {
    width: 55px;
    height: 55px;
    position: relative;
    font-style: italic;
    text-align: center;
    padding: 8px 3px 8px 0;
    letter-spacing: -5px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    font-size: 40px;
    line-height: 1;
    color: #0bb1ef;

    &::after {
      content: "";
      position: absolute;
      width: 55px;
      height: 55px;
      background: #eee;
      border-radius: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  .title-container {
    width: calc(100% - 55px);
    position: relative;
    padding-left: 20px;

    .title-post {
      font-size: 18px;
      font-weight: 700;
      height: auto;
      max-height: 93px;
      overflow: hidden;

      &:hover {
        color: #0bb1ef;
      }
    }
  }
}

.iklan {
  max-height: 400px;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.video-box {
  background: url("https://assets.pikiran-rakyat.com/www/2019/desktop/images/bg_tv.webp");

  .tx-video {
    .video {
      .icon-play {
        font-size: 60px;
        color: #fff;
        position: absolute;
        top: 84px;
        left: 140px;
        transition: all 0.2s ease;
      }

      &:hover .icon-play {
        font-size: 62px;
      }
    }
  }

  .video-info {
    padding: 10px 0;

    .video-title {
      height: auto;
      overflow: hidden;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      padding-right: 10px;
      border-right: 1px solid #fff;
    }

    .video-date {
      padding: 5px;
      font-size: 12px;
    }
  }
}

.footer {
  background: #000;
  color: #fff;
  border-top: 5px solid #0bb1ef;

  .brand {
    font-size: 20px;
    font-weight: bold;
  }

  .contact-information {
    line-height: 1.5;
  }
}

.navbar {
  .navbar-link {
    color: #000;
    position: relative;
    display: block;
    padding: 10px 7px;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    font-size: 16px;
    font-weight: bold;

    &:hover {
      color: #0bb1ef;
    }
  }

  .nav-more {
    .dropdown {
      opacity: 0;
      visibility: hidden;
      display: none;
      margin: 0;
      flex-wrap: wrap;
      position: absolute;
      right: 0;
      top: 45px;
      z-index: 1;
      padding: 10px 10px;
      width: min-content;
      min-width: 150px;
      background: #fff;
      box-shadow: 0 0 8px rgb(0 0 0 / 10%);
    }

    &:hover .dropdown {
      opacity: 1;
      visibility: visible;
      display: initial;
    }
  }
}

.mobile-nav {
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  .navbar-link {
    color: #000;
    position: relative;
    display: block;
    padding: 10px 7px;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    font-size: 16px;
    font-weight: 700;

    &:hover {
      color: #0bb1ef;
    }
  }
}

.social-icons {
  display: flex;
  align-items: center;

  a {
    display: inline-block;
    margin: 0 2px;
    font-size: 20px;
    color: #000;

    &:hover {
      color: #000;
    }
  }
}

.tags {
  a {
    text-decoration: none !important;
    display: inline-block !important;
    padding: 4px 8px;
    background: #0bb1ef !important;
    color: #fff !important;
    margin-right: 8px !important;

    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
}

#post-related {
  ul {
    li {
      padding: 15px 0;

      a {
        color: #000;
        display: block;
        text-decoration: none;
        font-size: 16px;
        font-weight: 700;

        &:hover {
          color: #0bb1ef;
        }
      }
    }
  }
}

.read-info {
  * {
    color: #0bb1ef;
  }

  letter-spacing: 1.3px;
  font-size: 14px;
  margin: 10px 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-title {
  font-family: Roboto, sans-serif;
  line-height: 1.3;
  color: #000;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  margin: 0 0 10px;
  font-size: 36px;
  font-weight: 700;
  color: #000;
}

.category-title {
  position: relative;
  padding: 15px 10px 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  font-style: italic;
  font-size: 24px;
  font-weight: 700;
  color: #fff;

  span {
    display: inline-block;
    margin: 0 auto;
    background: #0bb1ef;
    padding: 5px 15px;
    position: relative;
    height: 40px;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 35px;
    border-top: 1px solid #0bb1ef;
    left: 0;
    width: 100%;
  }
}

// Custom

#video-container {
  .slick-slide {
    padding: 0 20px;
  }
}

#slider-popular {
  margin-bottom: 0 !important;

  .slick-dots {
    bottom: 33px;
    text-align: right;
    padding-right: 20px;

    li {
      position: relative;
      display: inline-block;
      height: 10px;
      width: 10px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;

      button {
        border: 0;
        background: 0 0;
        display: block;
        height: 10px;
        width: 10px;
        outline: 0;
        line-height: 0;
        font-size: 0;
        color: transparent;
        padding: 5px;
        cursor: pointer;
        background: #fff;

        &::before {
          display: none;
        }
      }
    }

    .slick-active button {
      background: #0bb1ef;
    }
  }
}

#navbarBtnLeft,
#navbarBtnRight {
  display: none;
}

.navbar-custom {
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .nav {
    white-space: nowrap !important;
    flex-wrap: nowrap !important;
  }
}

// Custom
.linksisip {
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  border-left: 5px solid #c72026;

  .lihatjg {
    padding-left: 20px;

    strong {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 700;
    }

    a {
      display: block;
    }
  }

  a {
    font-size: 16px;
    color: #c72026 !important;
    font-weight: 600;

    &:hover {
      color: #c72026 !important;
    }
  }
}

#content {
  figure {
    max-width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
    }

    figcaption {
      width: 100%;
    }
  }

  iframe {
    width: 100%;
  }

  table {
    width: 100%;
  }
}

// Responsive

@media (max-width: 767.98px) {
  .list-post {
    li {
      .latest-title {
        font-size: 16px;
        max-height: 100%;
      }
    }
  }

  .video-box {
    background: url("https://assets.pikiran-rakyat.com/www/2019/desktop/images/bg_tv.webp");

    .tx-video {
      .video {
        .icon-play {
          font-size: 60px;
          color: #fff;
          position: absolute;
          top: 110px;
          left: 160px;
          transition: all 0.2s ease;
        }

        &:hover .icon-play {
          font-size: 62px;
        }
      }
    }

    .video-info {
      padding: 10px 0;

      .video-title {
        height: auto;
        overflow: hidden;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        padding-right: 10px;
        border-right: 1px solid #fff;
      }

      .video-date {
        padding: 5px;
        font-size: 12px;
      }
    }
  }

  .navbar-custom {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .nav {
      white-space: nowrap !important;
      flex-wrap: nowrap !important;
    }
  }

  #navbarBtnLeft {
    display: none;
  }

  #navbarBtnRight {
    display: initial;
  }
}

@media (max-width: 575.98px) {
  .list-post {
    li {
      .latest-title {
        font-size: 16px;
        max-height: 100%;
      }
    }
  }

  .video-box {
    background: url("https://assets.pikiran-rakyat.com/www/2019/desktop/images/bg_tv.webp");

    .tx-video {
      .video {
        .icon-play {
          font-size: 60px;
          color: #fff;
          position: absolute;
          top: 80px;
          left: 130px;
          transition: all 0.2s ease;
        }

        &:hover .icon-play {
          font-size: 62px;
        }
      }
    }

    .video-info {
      padding: 10px 0;

      .video-title {
        height: auto;
        overflow: hidden;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        padding-right: 10px;
        border-right: 1px solid #fff;
      }

      .video-date {
        padding: 5px;
        font-size: 12px;
      }
    }
  }
}

@media (min-width: 992px) {
  .navbar {
    overflow: initial;
  }
}
